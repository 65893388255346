<template>
  <v-footer
    id="footer"
    dark
    padless
    width="100%"
    class="grey darken-4 px-9 pt-9 pb-6">
    <div
      class="d-flex justify-center align-center flex-wrap"
      style="width: 100%">
      <div class="mx-12">
        <v-img
          :src="require('@/assets/logo_blanco.png')"
          width="180"
          class="ml-4"></v-img>
        <div class="text-left">
          <div class="py-1">
            <v-icon
              small
              class="mr-2"
              >mdi-phone</v-icon
            >
            <span>+56 9 9433 6976</span>
          </div>
          <div>
            <v-icon
              small
              class="mr-2"
              >mdi-email</v-icon
            >
            <span>contacto@ilpakamani.cl</span>
          </div>
        </div>
      </div>
      <div class="text-center mx-12">
        <div class="mb-n3 handwrite py-3">Síguenos en</div>
        <div style="white-space: nowrap">
          <v-btn
            x-large
            v-for="network in social"
            :key="`socicon_${network.name}`"
            icon
            @click="goToHref(network.href)">
            <v-icon large>{{ network.icon }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      style="width: 100%"
      class="text-center">
      <v-divider class="my-5"></v-divider>
      <div>{{ new Date().getFullYear() }} — Ilpakamani</div>
    </div>
    <!-- <v-card flat tile width="100%" class="text-center" dark>
            <v-container>
                <v-row>
                    <v-col align-self="center">
                        <v-img :src="require('@/assets/logo_blanco.png')" width="180" class="ml-4"></v-img>
                        <v-card-text class="text-left">
                            <div>
                                <v-icon small class="mr-2">mdi-phone</v-icon>
                                <span>+56 9 9433 6976</span>
                            </div>
                            <div>
                                <v-icon small class="mr-2">mdi-email</v-icon>
                                <span>contacto@ilpakamani.cl</span>
                            </div>
                        </v-card-text>
                    </v-col>
                    <v-col>
                        <v-card-text class="mb-n3 handwrite">Síguenos en</v-card-text>
                        <v-card-text>
                            <v-btn
                                x-large
                                v-for="network in social"
                                :key="`socicon_${network.name}`"
                                icon
                                @click="goToHref(network.href)"
                            >
                                <v-icon large>{{network.icon}}</v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-text>{{ new Date().getFullYear() }} — Ilpakamani</v-card-text>
        </v-card> -->
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      social: [
        {
          name: "Instagram",
          icon: "mdi-instagram",
          href: "https://www.instagram.com/ilpakamani/",
          tooltip: "@ilpakamani",
        },
        {
          name: "Facebook",
          icon: "mdi-facebook",
          href: "https://www.facebook.com/ilpakamanibanda/",
          tooltip: "ilpakamanibanda",
        },
        {
          name: "Youtube",
          icon: "mdi-youtube",
          href: "https://www.youtube.com/channel/UC3byXYPwMoPkO5WVW4HOUtQ",
        },
        {
          name: "Spotify",
          icon: "mdi-spotify",
          href: "https://open.spotify.com/artist/5kNW8KgFWLjtARYZpfiKeB",
        },
        {
          name: "Twitter",
          icon: "mdi-twitter",
          href: "https://twitter.com/ilpakamanibanda",
          tooltip: "ilpakamanibanda",
        },
      ],
    };
  },
  methods: {
    goToHref(href) {
      window.open(href, "_blank");
    },
  },
};
</script>
<style scoped>
/* @import url("https://fonts.googleapis.com/css?family=Fredericka+the+Great&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Exo&display=swap");

.handwrite {
  /* font-family: "Fredericka the Great", cursive !important; */
  font-family: "Exo", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
}
</style>
