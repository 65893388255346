import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import './components/base'
import '@/utils/ClickOutside'
import { Settings } from "luxon";

Settings.defaultLocale = 'es-CL'

Vue.config.productionTip = false

Array.prototype.rotate = function(n) {
  // TODO: support negatives
  let copy = JSON.parse(JSON.stringify(this))
  return copy.map((item, index, array) => array[(index + n) % array.length])
}

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
