import Dexie from 'dexie';
import { fetchData } from '../integrations/gsheets';
import { DateTime } from 'luxon';

const DATABASE_NAME = 'ilpadb'
export const db = new Dexie(DATABASE_NAME);
db.version(1).stores({
  events: '[DATE+NAME],TIME,FLYER_URL,VENUE,updatedAt',
  venues: '[VENUE_NAME+VENUE_ADDRESS],VENUE_CITY',
});

const DATA_MAX_AGE_DAYS = 1;
/** Retrieve data from indexedb if fresh. If too old, go fetch it. */
export async function getData(dropExisting = false) {
  if (dropExisting) {
    console.warn("Forcefully delete stored data first")
    db.delete({disableAutoOpen: false})
  }
  // Get stored data
  const events = await db.events.toArray();
  const venues = await db.venues.toArray();
  const lastUpdated = events.length == 0 ? DateTime.fromSeconds(0).toISO() : DateTime.fromJSDate(events[0].updatedAt)
  const now = DateTime.now()
  if (events.length > 0 && now.diff(lastUpdated, ['days']).days < DATA_MAX_AGE_DAYS) {
      console.log('Using cached data from Dexie');
      return {events: events.map(e => ({...e, DATE: DateTime.fromJSDate(e.DATE)})), venues};
  }


  console.log('Fetching new data from the server...');
  const {events: fetchedEvents, venues: fetchedVenues} = await fetchData(); // Replace with your API call

  // Save to dexie db
  await db.events.clear(); // Clear old data
  await db.venues.clear(); // Clear old data
  await db.events.bulkPut(fetchedEvents.map(item => ({...item, DATE: item.DATE.toJSDate(), updatedAt: DateTime.now().toJSDate()})),
   undefined,
   { allKeys: true})
  await db.venues.bulkPut(fetchedVenues, undefined, { allKeys: true})
  return {events: fetchedEvents, venues: fetchedVenues}
}