import axios from 'axios'
import { DateTime } from 'luxon'



/** approach using AppScript doGet. 
 * Currently has problems with CORS
 */
const SHEET_ENDPOINT_URL="https://script.google.com/macros/s/AKfycbyKxidofbxlrWlkAnkVTAlDGsaLOJDOoCPHGs8BqECLnyYTO7wTbJfvBxAXwXHRuWzW4A/exec"
const SHEETS = {
    NEXT_EVENTS: 'Eventos',
    TEST: 'TEST',
}

const getSheet = async (sheetName) => {
    return await axios.get(`${SHEET_ENDPOINT_URL}?sheet_name=${sheetName}`)
}

/** Fetches events and formats them to be easily read and used. */
export const fetchEvents_old = async () => {
    const data = (await getSheet(SHEETS.NEXT_EVENTS)).data
    const places = data.filter(d => d.Escenario.length).map(data => ({
        name: data.Escenario,
        city: data.Ciudad,
        address: data['Dirección']
    }))
    // Assume date is in America/Santiago
    return data.map(item => {
        const date = new Date(item.Fecha)
        return {
            date: `${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}`,
            name: item.Nombre,
            place: places.find(place => place.name === item.Lugar),
            flyer: item.Flyer
        }}
    )
}
export const fetchTest_old = () => getSheet(SHEETS.TEST)


/** Approach using published CSV
 * 
 */
import Papa from 'papaparse'

// const SHEET_ID = "12zYQWBLXdTGiwVOVYRnuZjx9ZpizufXD7a151B9BZkI"
const PUBLISH_ID ='2PACX-1vQnAZrrwesftdEv9uSTjMdx0B9nJdZjLwrqiZTAa9YPLa-1gVF81B_uDuUPd6nlgCpy2MaMNe0Czypw'

// csv headers
const DATE = 'Fecha'
const TIME = 'Hora'
const NAME = 'Nombre'
const FLYER_URL = 'Flyer'
const VENUE = 'Lugar'


const VENUE_NAME = 'Escenario'
const VENUE_CITY = 'Ciudad'
const VENUE_ADDRESS = 'Dirección'

/** Replaces headers with variable names and maps from list of list into a list of objects. */
const mapToObjects = (csvObj) => {
    // 1: replace headers
    let csvHeaders = csvObj.shift()
    const headers = {DATE, TIME, NAME, FLYER_URL, VENUE, VENUE_NAME, VENUE_CITY, VENUE_ADDRESS}
    Object.entries(headers).forEach(([k, v]) => {
        if (csvHeaders.indexOf(v) >= 0) {
            csvHeaders[csvHeaders.indexOf(v)] = k
        }
    })

    // 2: map to list of objects:
    return csvObj.map((valuesList) => valuesList.map((value, index) =>({[csvHeaders[index]]: value})).reduce((a, b) => ({...a, ...b}), {}))
}



String.prototype.nth_index_of = function(pattern, n) {
    var i = -1;

    while (n-- && i++ < this.length) {
        i = this.indexOf(pattern, i);
        if (i < 0) break;
    }

    return i;
}
/** Parses csv file into two halves, split by empty columns.
 * Filters out empty rows.
 */
function splitCsv(csvString){
    const EMPTY_COLUMNS_BETWEEN = 1
    const headersLine = csvString.split('\n')[0]
    const eventsHeaderCount =headersLine.split(',,')[0].split(',').length
    const venuesHeaderCount = headersLine.split(',,')[1].split(',').length
    const parsed = Papa.parse(csvString, { skipEmptyLines: true})
    const firstPart = parsed.data.map(row => row.slice(0, eventsHeaderCount)).filter(row => row.some((cell) => cell !== ""))
    const secondPart = parsed.data.map(row => row.slice(
        eventsHeaderCount + EMPTY_COLUMNS_BETWEEN,
        eventsHeaderCount + EMPTY_COLUMNS_BETWEEN + venuesHeaderCount )).filter(row => row.some((cell) => cell !== ""))
    return { firstPart: mapToObjects(firstPart), secondPart: mapToObjects(secondPart)}
}

export const fetchData = async() => {
    // const sheetName = 'Eventos'
    const url = `https://docs.google.com/spreadsheets/d/e/${PUBLISH_ID}/pub?output=csv`
    let response = await axios.get(url)
    const {data} = response
    // Split left (events) and right (venues) halves
    // Headers indicate the correct column positions. The rest of the rows must follow
    const { firstPart: eventsCsv, secondPart: venuesCsv} = splitCsv(data)

    const venues = venuesCsv

    const events = eventsCsv.map(event => ({
        ...event,
        venue: venues.find(v => v.VENUE_NAME.trim() === event.VENUE.trim()),
        DATE: DateTime.fromFormat(event.DATE, 'dd/MM/yyyy'),
    }))

    return {events, venues}
}
