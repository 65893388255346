<template>
  <v-container class="mt-12 mb-12">
    <div>
      <div class="display-1">Sobre Nosotros</div>
      <v-divider class="my-3"></v-divider>
      <v-img
        :width="`${imageWidth}`"
        contain
        class="float-right ml-10 mb-10"
        src="@/assets/img/ilpa-2023-curriculo.jpeg"></v-img>
      <div class="text-justify">
        <p class="body-2 text-justify">
          <span class="font-weight-bold">iLPAKAMANi</span> (rayo poderoso) en lengua Quechua o Runa Simi.<br />
          Es una banda Chilena de música étnica y folclor popular con más de 30 años de trayectoria y 4 discos editados,
          se ha presentado en conciertos en Chile y el Extranjero.
        </p>
        <p class="body-2 text-justify">
          <span class="font-weight-bold">iLPAKAMANi</span> es folclor actual de composición popular, social y urbana. Ha
          logrado una sonoridad propia y definida de vitalidad constante, que parte de las raíces andinas e indo de
          Latinoamérica.
        </p>
      </div>
      <div>
        <div class="title">SU PRESENTE COMO GRUPO</div>
        <p class="body-2 text-justify">
          Gestando su propia carrera, ilpakamani se encuentra en la difusión de su cuarto disco titulado “BAMBÚ” que lo
          ha llevado a estar en el listado de los premios Pulsar como el mejor disco del año 2024 en la categoría música
          de pueblos originarios.
        </p>
        <div class="title">LUTHERÍA Y MÚSICA</div>
        <p class="body-2 text-justify">
          Ha desarrollado su propio taller de construcción de instrumentos de aerófonos andinos en todas sus variedades
        </p>
        <div class="title">INICIOS</div>
        <p class="body-2 text-justify">
          iLPAKAMANi nace en 1992 con la intención de difundir en sus inicios la música andina.
        </p>
        <p class="body-2 text-justify">
          A través del tiempo ha ido agregando nuevas construcciones armónicas y sonoras con esbozos y técnicas de bases
          musicales ligadas a corrientes de la música contemporánea, lo que le ha entregado una nueva mixtura a su
          trabajo musical.
        </p>
        <p class="body-2 text-justify">
          iLPAKAMANi utiliza una gran variedad de instrumentos musicales de diversas zonas y orígenes. Los aerófonos
          andinos y nativos más ancestrales: zampoñas en todas sus familias, quena, quenacho, jachaquena, moceños,
          tarkas, sicuras, trutrucas, pifilca, diyiridú. También utiliza bronces como distintos saxofones. De las
          cuerdas de América latina ejecutan charango, cuatro Venezolano, tiple Colombiano, además utiliza guitarras
          electro acústicas, bajo eléctrico.
        </p>
        <p class="body-2 text-justify">
          Interpretan además instrumentos de percusión tales como bombo, bongó, congas, kultrún, accesorios y
          percusiones latinas.
        </p>
      </div>
    </div>
    <v-row
      justify="center"
      class="px-3">
      <v-btn
        class="float-right mt-4"
        outlined
        :loading="loadingBook"
        @click.prevent="downloadPortfolio()">
        Descarga nuestro Book de trabajo
        <v-icon
          class="ml-3"
          secondary
          >mdi-file-pdf-box</v-icon
        >
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    imageWidth() {
      return this.$vuetify.breakpoint.mdAndDown ? "100%" : "60%";
    },
  },
  data() {
    return {
      loadingBook: false,
    };
  },
  methods: {
    downloadPortfolio() {
      this.loadingBook = true;
      fetch(`BOOK DE TRABAJO ILPA KAMANI 2025.pdf`)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = `Book-de-trabajo-Ilpakamani-${new Date().getFullYear()}.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.loadingBook = false;
        })
        .catch(() => alert("oh no!"));
    },
  },
};
</script>
