import { render, staticRenderFns } from "./IlpaSocialNetworks.vue?vue&type=template&id=078b4906&scoped=true"
import script from "./IlpaSocialNetworks.vue?vue&type=script&lang=js"
export * from "./IlpaSocialNetworks.vue?vue&type=script&lang=js"
import style0 from "./IlpaSocialNetworks.vue?vue&type=style&index=0&id=078b4906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "078b4906",
  null
  
)

export default component.exports