<template>
  <v-container
    fluid
    class="pa-0 container white"
    style="min-height: 100vh">
    <v-img
      :key="`image_${clientHeight}`"
      class="container__img white"
      cover
      height="100vh"
      :src="require('@/assets/' + bgImage)">
      <v-btn
        icon
        :loading="loading"
        class="float-right"
        style="opacity: 0.5"
        @click="fetchEvents(true)">
        <v-icon color="grey">mdi-refresh</v-icon>
      </v-btn>
    </v-img>
    <div
      class="row justify-center align-center"
      style="min-height: 100vh"
      v-if="loading">
      <div class="col-6">
        <div
          class="text-center text-quote mb-1"
          style="position: relative; color: black">
          Cargando eventos...
        </div>
        <v-progress-linear
          color="secondary"
          height="10"
          indeterminate
          rounded></v-progress-linear>
      </div>
    </div>
    <div
      class="row justify-center align-center"
      v-else-if="fetchError"
      style="min-height: 100vh">
      <div
        class="text-center text-quote mb-1"
        style="position: relative; color: black">
        Ocurrió un error al cargar eventos. Por favor
        <v-btn
          small
          plain
          color="secondary"
          @click="fetchEvents(true)">
          <strong> inténtalo de nuevo </strong></v-btn
        >
      </div>
    </div>
    <v-container
      class="container__content"
      v-else>
      <base-title ref="container__title">Próximos Eventos</base-title>
      <v-row
        justify="center"
        :style="`height: calc(100vh - ${titleHeightCss}); overflow-y: scroll;`">
        <v-col
          md="10"
          sm="12">
          <v-list
            three-line
            subheader
            flat
            color="transparent"
            class="overflow-y-auto overflow-x-auto">
            <div
              v-for="(event, index) in sortedEvents"
              :key="`event_${event.DATE}_${index}`">
              <v-list-item v-if="isFirstExpiredEvent(index)">
                <v-list-item-content class="display-1 text-center ml-auto mr-auto">
                  <div class="text-center">Pasados</div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="my-4"
                :class="eventExpired(event.DATE) ? 'list-item--expired' : ''">
                <v-list-item-avatar
                  tile
                  :size="`${$vuetify.breakpoint.mdAndDown ? '60' : '100'}`"
                  style="margin-top: auto; margin-bottom: auto">
                  <v-img
                    v-if="!!event.FLYER_URL && !erroredImages.includes(event.FLYER_URL)"
                    :src="event.FLYER_URL"
                    :class="`${eventExpired(event.DATE) ? 'expired' : ''} avatarimg`"
                    @click="overlay = event.FLYER_URL"
                    @error="erroredImages.push(event.FLYER_URL)">
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular
                          color="grey-lighten-4"
                          indeterminate></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                  <div v-else>
                    <div style="filter: grayscale(1); opacity: 0.5">
                      <v-icon>mdi-image-off</v-icon>
                      <div
                        class="caption"
                        style="user-select: none">
                        Sin imagen
                      </div>
                    </div>
                  </div>
                </v-list-item-avatar>
                <div
                  :class="`${$vuetify.breakpoint.mdAndDown ? 'mr-2' : 'mx-10'} ${
                    eventExpired(event.DATE) ? 'text--secondary' : ''
                  }`">
                  <v-list-item-title>
                    <div class="display-1 text-center">{{ event.DATE.toObject().day }}</div>
                    <div class="body-1 text-center text-uppercase">
                      {{ event.DATE.toFormat("MMM yyyy") }}
                    </div>
                  </v-list-item-title>
                </div>
                <v-list-item-content class="text-right">
                  <v-list-item-title
                    :class="`${$vuetify.breakpoint.mdAndDown ? 'body-1' : 'title'} text-uppercase font-weight-bold ${
                      eventExpired(event.DATE) ? 'text--secondary' : ''
                    }`"
                    style="white-space: pre-wrap">
                    <span v-if="event.NAME">{{ event.NAME }}&nbsp;-&nbsp;</span>
                    <span>{{ event.venue.VENUE_CITY }}</span>
                  </v-list-item-title>
                  <a @click="searchLocationInMaps(event.venue)">
                    <v-list-item-subtitle>
                      <v-icon>mdi-map-marker</v-icon>
                      {{ event.venue.VENUE_NAME }}: {{ event.venue.VENUE_ADDRESS }}
                    </v-list-item-subtitle>
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < events.length - 1"></v-divider>
            </div>
          </v-list>
        </v-col>
      </v-row>
      <v-dialog
        v-model="showOverlay"
        fullscreen
        content-class="flyer-dialog text-center elevation-0 h-100 w-100">
        <!-- <div> -->
        <!-- <v-btn @click="overlay=false" class="float-right">
                    Cerrar
                    <v-icon>mdi-close</v-icon>
                </v-btn>-->
        <div class="h-100 w-100 d-flex flex-wrap justify-center align-center">
          <v-img
            @click="overlayZoom = !overlayZoom"
            v-if="showOverlay"
            :src="overlay"
            class="flyer"
            :style="`${!overlayZoom ? 'max-width: 60vw;' : 'width: 90vw'}`"
            v-click-outside="onCloseOverlay"
            loading="lazy">
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular
                  color="grey-lighten-4"
                  indeterminate></v-progress-circular>
              </div>
            </template>
          </v-img>
          <v-btn
            @click="showOverlay = false"
            color="white"
            fab
            large
            elevation="10"
            style="position: fixed; top: 0; right: 0; margin: 2rem">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>
        <!-- </div> -->
      </v-dialog>
    </v-container>
  </v-container>
</template>
<script>
import { DateTime } from "luxon";
import { getData } from "../db/db";

export default {
  data() {
    return {
      bgImage: "img/slides/DSC_0180 teatro 4.jpg",
      overlay: null,
      overlayZoom: true,
      events: [],
      erroredImages: [],
      clientHeight: 0,
      titleHeightCss: 0,
      loading: false,
      fetchError: false,
    };
  },
  mounted() {
    this.fetchEvents();
  },
  methods: {
    async fetchEvents(invalidateExisting = false) {
      this.loading = true;
      try {
        const { events } = await getData(invalidateExisting);
        this.events = events;
        this.recomputeTitleHeight();
      } catch (error) {
        // console.error("Error retrieving events: ", error);
        this.fetchError = true;
      } finally {
        this.loading = false;
      }
    },
    recomputeTitleHeight() {
      this.$nextTick().then(() => {
        const el = this.$refs.container__title?.$el;
        if (!el) {
          return;
        }
        this.clientHeight = `${el.clientHeight}px`;
        const { marginTop, marginBottom } = el.currentStyle || window.getComputedStyle(el);
        this.titleHeightCss = `calc(${[this.clientHeight, marginTop, marginBottom].join(" + ")})`;
      });
    },
    searchLocationInMaps(venue) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${venue.VENUE_NAME} ${venue.VENUE_ADDRESS}, ${venue.VENUE_CITY}`,
        "_blank"
      );
    },
    eventExpired(eventDate) {
      return DateTime.now() > eventDate;
    },
    onCloseOverlay() {
      this.overlay = false;
    },
    isFirstExpiredEvent(sortedEventIndex) {
      const thisEvent = this.sortedEvents[sortedEventIndex];
      const prevEvent = sortedEventIndex > 0 ? this.sortedEvents[sortedEventIndex - 1] : null;
      return prevEvent
        ? !this.eventExpired(prevEvent.DATE) && this.eventExpired(thisEvent.DATE)
        : this.eventExpired(thisEvent.DATE);
    },
  },
  computed: {
    showOverlay: {
      get: function () {
        return Boolean(this.overlay);
      },
      set: function (newVal) {
        this.overlay = newVal;
      },
    },
    // Sorted by date, newest first
    sortedEvents() {
      return [...(this.events ?? [])].sort((e1, e2) => e2.DATE - e1.DATE);
    },
  },
  watch: {
    overlay(newval) {
      if (newval) {
        this.overlayZoom = false;
      }
    },
    events: {
      async handler(newVal) {
        if (!!newVal) {
          this.recomputeTitleHeight();
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.container {
  position: relative;
  &__img {
    position: absolute;
  }
}
.avatarimg {
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.avatarimg:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.container__img > .v-image__image {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  opacity: 0.5;
  filter: grayscale(90%) brightness(2);
}

.container__img > div {
  background-color: #ffffff55;
}

.expired {
  filter: grayscale(100%);
}
.list-item--expired {
  opacity: 0.7;
  color: blue;
}
.container__content {
  color: black !important;
  .theme--dark {
    // ::v-deep &.v-application {
    // & .text--secondary {
    div,
    span {
      color: black !important;
    }
    i {
      color: $ilpa-red;
    }
    // }
    // }
  }
}

.flyer-dialog {
  background-color: rgba(black, 70%);
}
</style>
