<template>
  <section
    id="top"
    style="background-color: black">
    <v-container
      fluid
      class="fill-height"
      no-gutters
      style="padding: 0">
      <v-carousel
        :autoplay="true"
        :autoplayHoverPause="false"
        :interval="slideshow.delay"
        hide-delimiters
        cycle
        :show-arrows="false"
        touchless
        :height="carouselHeight"
        width="100vw"
        v-model="slideshow.index">
        <v-carousel-item
          v-for="(slide, i) in slideshow.slides"
          :key="`slide_${i}`">
          <v-img
            :src="require('@/assets/img/slides/' + slide.src)"
            cover
            height="100%"
            width="100%"
            :class="{ 'slideshow-img': true, 'slideshow-img-leave': slideshow.index != i }">
            <v-row class="fill-height">
              <v-col align-self="end">
                <span class="slide-title float-right text-uppercase font-italic font-weight-light">{{
                  slide.title
                }}</span>
              </v-col>
            </v-row>
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <div class="text-center main-logo">
        <!-- <v-img width="30vw" src="@/assets/logo_big.png" position="center" contain> -->
        <v-row class="fill-height">
          <v-col
            align-self="end"
            md="12"
            style="overflow: hidden; width: 100vw">
            <transition
              name="phrase-transition"
              mode="out-in">
              <div
                class="white--text text-weight-bolder mb-n3 phrase d-flex align-center justify-center"
                :key="`animatedelement_${currentPhraseIndex}`"
                style="margin-top: auto; margin-bottom: auto; height: 100vh; max-width: 100vw">
                <div v-if="currentAnimatedElement.text">{{ currentAnimatedElement.text }}</div>
                <v-img
                  style="max-width: 30vw; min-width: 320px"
                  contain
                  v-else-if="currentAnimatedElement.img"
                  :src="require('@/assets/' + currentAnimatedElement.img)"></v-img>
                <component
                  v-else-if="currentAnimatedElement.component"
                  v-bind:is="currentAnimatedElement.component"
                  :delay="currentAnimatedElement.phraseDelay"
                  :phrases="currentAnimatedElement.phrases"
                  :preface="currentAnimatedElement.phrasePreface"></component>
                <div v-else>{{ currentAnimatedElement }}</div>
              </div>
            </transition>
          </v-col>
        </v-row>
        <!-- </v-img> -->
      </div>
    </v-container>
  </section>
</template>

<script>
import AnimatedPhrases from "./AnimatedPhrases";
import PhraseComponent from "./PhraseComponent";

export default {
  components: { AnimatedPhrases, PhraseComponent },
  data() {
    return {
      slideshow: {
        slides: [
          // src relative to @/assets/img/slides
          {
            title: "",
            src: "1737565940256.jpg",
          },
          {
            title: "",
            src: "1737565940272.jpg",
          },
          {
            title: "",
            src: "1737565940310.jpg",
          },
          {
            title: "",
            src: "1737565940364 copia.jpg",
          },
          {
            title: "",
            src: "DSC_0180 teatro 1.jpg",
          },
          {
            title: "",
            src: "DSC_0180 teatro 2.jpg",
          },
          {
            title: "",
            src: "DSC_0180 teatro 3.jpg",
          },
          {
            title: "",
            src: "DSC_0180 teatro 4.jpg",
          },
          {
            title: "Alberto Huenual",
            src: "DSC_0180 teatro beto.jpg",
          },
          {
            title: "Cristian Martínez",
            src: "DSC_0180 teatro criss.jpg",
          },
          {
            title: "",
            src: "DSC_0180 teatro ilpa.jpg",
          },
          {
            title: "",
            src: "DSC_0180 teatro ilpa 2.jpg",
          },
          {
            title: "Cali Colombia, 2018",
            src: "DSC_0180 teatro ilpa aire libre.jpg",
          },
          {
            title: "",
            src: "foto aire libre.jpg",
          },
          {
            title: "",
            src: "foto condell.jpg",
          },
          {
            title: "",
            src: "foto condell 2.jpg",
          },
          {
            title: "",
            src: "foto condell 3.jpg",
          },
          {
            title: "",
            src: "loma.jpg",
          },
          {
            title: "",
            src: "recoleta.jpg",
          },
          {
            title: "",
            src: "teatro cousino.jpg",
          },
        ],
        index: 0,
        delay: 5000,
      },
      titleAnimation: [
        {
          component: AnimatedPhrases,
          phraseDelay: 1800,
          phrasePreface: "Nuestra música es",
          phrases: ["étnica", "popular", "indo de latinoamérica", "original", "vital y definida"],
          // phrases: ["indo de latinoamérica"]
        },
        {
          component: PhraseComponent,
          delay: 2700,
        },
        {
          img: "logo_big.png",
          delay: 2200,
        },
      ],
      currentPhraseIndex: 0,
      titleAnimationTimeout: undefined,
    };
  },
  mounted() {
    // Compute delay for nested phrases component
    this.titleAnimation.map((item) => {
      if (item.component && item.phraseDelay && item.phrases) {
        item.delay = item.phraseDelay * item.phrases.length;
      }
    });
    // Start timeout loop
    var internalCallback = () => {
      this.currentPhraseIndex = (this.currentPhraseIndex + 1) % this.titleAnimation.length;
      this.titleAnimationTimeout = setTimeout(internalCallback, this.currentAnimatedElement.delay);
    };
    this.titleAnimationTimeout = setTimeout(internalCallback, this.currentAnimatedElement.delay);
  },
  beforeDestroy() {
    clearInterval(this.titleAnimationTimeout);
  },
  computed: {
    currentAnimatedElement() {
      return this.titleAnimation[this.currentPhraseIndex];
    },
    carouselHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? "100vh" : "60vh";
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Fredericka+the+Great&display=swap");

.slideshow-img {
  filter: brightness(0.6);
  transition: opacity 0.1s ease;
  /* filter: hue-rotate(10deg); */
}

.slideshow-img-leave {
  opacity: 0;
}

.slide-title {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: bold;
  font-family: $font-accent;
  /* float: right; */
  font-size: 1rem;
  padding: 4px;
  border-radius: 8px;
  margin: 20px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  user-select: none;
}

.main-logo {
  position: absolute;
  margin-left: 50vw;
  transform: translateX(-50%);
}

.phrase {
  font-family: $font-accent;
  font-size: 2.4rem;
  font-weight: bolder;
  width: 100%;
  word-break: break-word;
}
.phrase-transition-enter-active {
  transition: all 0.4s ease;
}
.phrase-transition-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.phrase-transition-enter {
  transform: translateX(30px);
  opacity: 0;
}
.phrase-transition-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
